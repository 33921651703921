import { useStaticQuery, graphql } from 'gatsby';

const infoChoice = (data: Array<any> = [], classification: Array<string> = [], count = 3, skip = 0) => {
  const ary = data.filter((item) => {
    //終了日除外
    const currentDate = new Date()
    currentDate.setHours(0)
    currentDate.setMinutes(0)
    const eventEndDate = new Date(item.node.date_range?.end || "")
    eventEndDate.setDate(eventEndDate.getDate())
    eventEndDate.setHours(23)
    eventEndDate.setMinutes(59)
    if (item.node.date_range?.end && currentDate > eventEndDate) {
      return false
    }

    //区分フィルター
    if (classification.length > 0) {
      if (!item.node.classification.some((el: string) => classification.includes(el))) {
        return false
      }
    }

    return true
  })
  return ary.slice(skip, count == 0 ? undefined : count)
}

export default infoChoice