import { useStaticQuery, graphql } from 'gatsby';

const infoGet = () => {
  const newsdataquery = useStaticQuery(graphql
    `query{
        allMicrocmsInfoRph (sort: {fields: sortIndex, order: ASC}){
          edges {
            node {
              pdf {
                url
              }
              title
              publishedAt
              link {
                href
                blank
              }
              date_range {
                start
                end
              }
              classification
              category
              recommend
              infoRphId
            }
          }
        }
      }`
  )
  const newsdata = newsdataquery.allMicrocmsInfoRph.edges
  return newsdata
}

export default infoGet