import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CSectTitle03,
  CNewsList,
  CBtnList,
  CHeroSlider,
  CFocalCard,
  CTable02,
  CBreadCrumb,
  CSimpleSlider,
} from '../../../../components/_index';
import infoChoice from '../../../../utils/info-choice';
import infoGet from '../../../../utils/info-get';
import '../../../../assets/_sass/page/loccitane_spa.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: (
              <>
                LA VILLA Spa by <br className="u_pc" />
                L’OCCITANE Tokyo
              </>
            ),
            sub: '',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/facility/loccitane_spa/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/facility/loccitane_spa/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>

      <section className="u_pt100">
        <LWrap>
          <CSectTitle03 title="お知らせ" />
          <CNewsList
            data={infoChoice(infoGet(), ['ロクシタンスパ'], 5)}
            label={false}
          />
          <CBtnList
            data={[
              {
                label: 'お知らせ一覧',
                color: 'borderBlack',
                link: {
                  href: '/information/',
                },
              },
            ]}
          />
        </LWrap>
      </section>

      <div className="p_spa">
        <section className="u_pt100 u_pb120">
          <LWrap>
            {/* <CHeroSlider
              data={{
                imgList: [
                  {
                    img: {
                      src: '/assets/images/about/facility/loccitane_spa/img_spa.png',
                      alt: '',
                    },
                    imgSp: {
                      src: '/assets/images/about/facility/loccitane_spa/img_spa__sp.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/about/facility/loccitane_spa/img_spa02.png',
                      alt: '',
                    },
                    imgSp: {
                      src: '/assets/images/about/facility/loccitane_spa/img_spa02__sp.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/about/facility/loccitane_spa/img_spa03.png',
                      alt: '',
                    },
                    imgSp: {
                      src: '/assets/images/about/facility/loccitane_spa/img_spa03__sp.png',
                      alt: '',
                    },
                  },
                ],
                title: <>自然の恵み豊かなプロヴァンスの世界へ</>,
                text: (
                  <>
                    ロクシタン製品を使用したリラクゼーションサロン「LA VILLA Spa
                    by L’OCCITANE
                    Tokyo」では、厳選された自然原料と世界中の伝統的なトリートメント技術の融合で、世界中にファンを持つロクシタンスパのトリートメントをご堪能いただけます。
                    <br />
                    心地よい香りとやすらぎに満ちた空間で、確かな技術と経験から培われた独自のトリートメントはお客様を真の感覚へと導き、自然の恵み豊かなプロヴァンスの世界へお招きします。
                  </>
                ),
              }}
            /> */}

            <CSimpleSlider
              width="1040px"
              exClass="u_mb30"
              data={[
                {
                  img: {
                    src: '/assets/images/about/facility/loccitane_spa/img_spa.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/about/facility/loccitane_spa/img_spa__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/facility/loccitane_spa/img_spa02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/about/facility/loccitane_spa/img_spa02__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/facility/loccitane_spa/img_spa03.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/about/facility/loccitane_spa/img_spa03__sp.png',
                    alt: '',
                  },
                },
              ]}
            />
            <h3 className="c_articleHeadingLv4 u_tac_pc u_fwm">
              自然の恵み豊かなプロヴァンスの世界へ
            </h3>
            <p className="c_sectLead">
              ロクシタン製品を使用したリラクゼーションサロン「LA VILLA Spa by
              L’OCCITANE Tokyo」では、厳選された自然原料と世界中の伝統的な
              <br className="u_pc" />
              トリートメント技術の融合で、世界中にファンを持つロクシタンスパのトリートメントをご堪能いただけます。
              <br />
              心地よい香りとやすらぎに満ちた空間で、確かな技術と経験から培われた独自のトリートメントはお客様を真の感覚へと導き、
              <br className="u_pc" />
              自然の恵み豊かなプロヴァンスの世界へお招きします。
            </p>
          </LWrap>
        </section>

        <section className="u_pb120">
          <LWrap>
            <CSectTitle03 title="トリートメントルーム" />
            <p className="c_sectLead">
              「LA VILLA Spa by L’OCCITANE
              Tokyo」では、4つの完全個室のトリートメントルームをご用意しております。
              <br />
              トリートメントルームごとにロクシタンの人気シリーズをイメージした、自然の恵み豊かなプロヴァンスを感じられる空間となっております。
              <br />
              なお、"アクアレオティエ"を使用したトリートメントメニューは、当ホテルのみでご提供する特別メニューです。
            </p>
            <CFocalCard
              data={[
                {
                  img: {
                    src: '/assets/images/about/facility/loccitane_spa/img_room.png',
                    alt: '',
                  },
                  title: {
                    en: <>Immortelle</>,
                    ja: <>イモーテル</>,
                  },
                  text: (
                    <>
                      イモーテルは摘み取った後も鮮やかな色を保ち続ける、類まれなる生命力をもつ神秘の花。「永久花」と呼ばれるイモーテルから抽出した貴重なエッセンシャルオイルは、古来より高い再生力があると言われています。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/about/facility/loccitane_spa/img_room02.png',
                    alt: '',
                  },
                  title: {
                    en: <>Aqua Reotier</>,
                    ja: <>アクアレオティエ</>,
                  },
                  text: (
                    <>
                      自然あふれる南仏プロヴァンス地方の小さな町レオティエ。そこから流れ出る温水は、カルシウム含有量が高いミネラルを豊富に含み、肌をたっぷりのうるおいで満たします。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/about/facility/loccitane_spa/img_room03.png',
                    alt: '',
                  },
                  title: {
                    en: <>Lavande</>,
                    ja: <>ラベンダー</>,
                  },
                  text: (
                    <>
                      プロヴァンスを代表するハーブ、ラベンダー。リラックス効果に優れ、プロヴァンスの大自然に包みこまれるような香りで、穏やかな時間を過ごせます。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/about/facility/loccitane_spa/img_room04.png',
                    alt: '',
                  },
                  title: {
                    en: <>Rose</>,
                    ja: <>ローズ</>,
                  },
                  text: (
                    <>
                      幸せを呼び込むパワーを持つプロヴァンスのローズ。摘みたてのまま閉じ込めたフレッシュなローズと、ローズが咲く庭に漂うさまざまな草花や果実の自然の香りも届けます。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="p_spa_menu u_pb120">
          <LWrap>
            <CSectTitle03 title="メニュー" />
            <CTable02
              data={[
                {
                  title: 'ボディ',
                  content: (
                    <>
                      <div className="inline_table">
                        <div className="row">
                          <div className="cell">
                            リラクシング アロマコロジー トリートメント　60分
                          </div>
                          <div className="cell u_fwb">25,000円</div>
                        </div>
                        <div className="row">
                          <div className="cell">
                            シア ナーチャリング トリートメント　90分
                          </div>
                          <div className="cell u_fwb">30,000円</div>
                        </div>
                      </div>
                    </>
                  ),
                },
                {
                  title: 'フェイシャル',
                  content: (
                    <>
                      <div className="inline_table">
                        <div className="row">
                          <div className="cell u_minW">
                            イモーテル ディヴァイン シークレット 90分
                          </div>
                          <div className="cell u_fwb">33,000円</div>
                        </div>
                      </div>
                    </>
                  ),
                },
                {
                  title: 'フェイシャル&ボディ',
                  content: (
                    <>
                      <div className="inline_table">
                        <div className="row">
                          <div className="cell u_minW">
                            ディスカバリー エクスペリエンス　90分
                          </div>
                          <div className="cell u_fwb">32,000円</div>
                        </div>
                        <div className="row">
                          <div className="cell">
                            アクアレオティエ リフレッシング デライト　90分
                          </div>
                          <div className="cell u_fwb">32,000円</div>
                        </div>
                      </div>
                    </>
                  ),
                },
              ]}
            />
            <ul className="c_noteList u_mt20">
              <li>料金には消費税が含まれます。</li>
            </ul>
            <CBtnList
              data={[
                {
                  label: 'ご予約',
                  color: 'bgTheme',
                  link: {
                    href: 'https://reservia.jp/shop/reserve/697cd2f414',
                    blank: true,
                  },
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="p_spa_overview u_pb100">
          <LWrap>
            <CSectTitle03 title="施設概要" />
            <CTable02
              data={[
                {
                  title: 'フロア',
                  content: (
                    <>
                      <div className="inline_table">
                        <div className="row">
                          <div className="cell">当ホテル5F</div>
                        </div>
                      </div>
                    </>
                  ),
                },
                {
                  title: '営業時間',
                  content: (
                    <>
                      <div className="inline_table">
                        <div className="row">
                          <div className="cell">
                            12:00～21:00（最終入店20:00）
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                },
                {
                  title: 'お問合せ',
                  content: (
                    <>
                      <div className="inline_table">
                        <div className="row">
                          <div className="cell">
                            TEL <a href="tel:0356414641">03-5641-4641</a>
                            （直通）
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
      </div>

      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'ホテルについて',
              path: '/about/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
